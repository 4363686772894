import { Banner } from "../../Utils/webui/Banner";
import { NavBar } from "./NavBar";

export const Header = () => {
  return (
    <>
      <Banner />
      <NavBar />
    </>
  );
};

import { ChakraProps, EditableInput, Flex, FormLabel } from "@chakra-ui/react";
import { EditableField } from "../../Utils/webui/EditableField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { updateUser } from "../../../writerlogapi/user";

type TargetFieldProps = {
  contents: number;
  isEditable?: boolean;
  rest?: ChakraProps;
};
export const TargetField = ({
  contents,
  isEditable = false,
  ...rest
}: TargetFieldProps) => {
  const { currentUser, accessToken } = useAppContext();
  const queryClient = useQueryClient();
  let [fieldContents, setFieldContents] = useState(contents);

  const userUpdate = useMutation({
    mutationFn: (data: {}) => updateUser(currentUser.sub!, accessToken, data),
    onSuccess: (newProfile) =>
      queryClient.setQueryData(["user"], currentUser.sub, newProfile),
  });

  async function handleSubmit(contents: string) {
    // Set the contents to the new value (obviously we should error check)
    if (parseInt(contents)) {
      setFieldContents(parseInt(contents));
      // And update the back end
      console.log(contents);
      userUpdate.mutate({ target: contents });
    }
  }
  async function handleCancel() {
    // Reset to the current value, erases the changes.
    setFieldContents(fieldContents);
  }

  return (
    <Flex width="100%" flexDirection={"column"}>
      <EditableField
        onSubmit={(x: string) => handleSubmit(x)}
        onCancel={handleCancel}
        isEditable={isEditable}
        type={EditableInput}
        {...rest}
      >
        {contents.toLocaleString()}
      </EditableField>
      <FormLabel fontSize={"xs"}>words per day</FormLabel>
    </Flex>
  );
};

import { ChakraProps, EditableInput } from "@chakra-ui/react";
import { EditableField } from "../../Utils/webui/EditableField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { updateUser } from "../../../writerlogapi/user";

type DisplayNameProps = {
  contents: string;
  isEditable?: boolean;
  rest?: ChakraProps;
};
export const DisplayName = ({
  contents,
  isEditable = false,
  ...rest
}: DisplayNameProps) => {
  // const { contents, isEditable = false, ...rest } = props;

  const { currentUser, accessToken } = useAppContext();
  const queryClient = useQueryClient();

  const userUpdate = useMutation({
    mutationFn: (data: {}) => updateUser(currentUser.sub!, accessToken, data),
    onSuccess: (newProfile) =>
      queryClient.setQueryData(["user"], currentUser.sub, newProfile),
  });

  async function handleSubmit(contents: string) {
    // Set the contents to the new value (obviously we should error check)
    setFieldContents(contents);
    // And update the back end
    userUpdate.mutate({ name: { displayName: contents } });
  }
  async function handleCancel() {
    // Reset to the current value, erases the changes.
    setFieldContents(fieldContents);
  }

  let [fieldContents, setFieldContents] = useState(contents);

  return (
    <EditableField
      onSubmit={(x: string) => handleSubmit(x)}
      onCancel={handleCancel}
      isEditable={isEditable}
      type={EditableInput}
      {...rest}
    >
      {contents}
    </EditableField>
  );
};

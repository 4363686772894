import { Flex, ChakraProps } from "@chakra-ui/react";
import { useAppContext } from "../libs/contextLib";
import { useQuery } from "@tanstack/react-query";
import { fetchUser } from "../writerlogapi/user";

import { PageLayout } from "../components/PageLayout/webui/PageLayout";
import { UserProfile } from "../components/Profile/webui/UserProfile";
import { BadgesCard } from "../components/Badges/webui/BadgesCard";
import { UserWords } from "../components/Words/webui/UserWords";
import { UserStreaks } from "../components/Streaks/webui/UserStreaks";
import { UserProjects } from "../components/Projects/webui/ProjectCards";
import { UserTotals } from "../components/Totals/webui/UserTotals";

import { Loading } from "../components/Loading/webui/Loading";
import { NoData } from "../components/NoData/webui/NoData";

export const ProfilePage = (props: ChakraProps) => {
  const { currentUser, accessToken } = useAppContext();
  // Queries
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });
  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  return (
    <PageLayout>
      <>
        <UserProfile marginBottom={"1em"} width={["100%"]} {...props} />
        <Flex
          marginBottom="1em"
          flexDirection={{ base: "column", md: "row" }}
          alignItems={"space-between"}
          justifyContent={"space-between"}
          flexWrap="wrap"
          gap="1em"
        >
          <UserStreaks {...props} width={["100%", "auto"]} />
          <UserTotals {...props} width={["100%", "auto"]} />
        </Flex>
        <UserWords {...props} marginBottom="1em" />
        <BadgesCard {...props} />
        <UserProjects {...props} />
      </>
    </PageLayout>
  );
};

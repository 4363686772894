// React
import { useContext, createContext } from 'react';
// Authentication
import { User } from '@auth0/auth0-react';

const appCtxDefaultValue = {
    currentUser: new User(),
    accessToken: '',
    isLoading: true,
};

const AppContext = createContext(appCtxDefaultValue);

const useAppContext = () => useContext(AppContext);

export { AppContext, useAppContext };

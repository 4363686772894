import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";

export const fetchUser = async ({
  queryKey,
}: QueryFunctionContext): Promise<UserProfile | null> => {
  const [_key, user, token] = queryKey;
  if (_key !== "user") {
    throw new Error("Trying to query user function with wrong key");
  }
  if (!user || !token) {
    return null;
  }

  const res = await axios.get(
    `${process.env.REACT_APP_API_SERVER_URL}api/user/${user}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return res.data;
};

export const updateUser = async (user: string, token: string, data: {}) => {
  let res = axios.post(
    `${process.env.REACT_APP_API_SERVER_URL}api/user/${user}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return (await res).data;
};

export const createUser = async (user: string, token: string, data: {}) => {
  let res = await axios.put(
    `${process.env.REACT_APP_API_SERVER_URL}api/user/${user}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
};

// Run at first login

import { User } from "@auth0/auth0-react";
import { capitalizeFirstLetter } from "../../../libs/strings";

const emptyWords: Words = {
  total: 0,
};

const emptyUserTotals: UserTotals = {
  words: emptyWords,
  days: 0,
};

const emptyUserSocial: UserSocials[] = [
  {
    type: "",
    url: "",
  },
];

export const buildUser = (user: User) => {
  // In reality, we won't get undefined values from Auth0 for most of these
  // But Typescript knows they _might_ be, so it whines
  let newUser: UserProfile = {
    version: 1,
    userID: user.sub || "",
    name: {
      displayName: capitalizeFirstLetter(user.nickname || "User"),
      firstName: capitalizeFirstLetter(user.nickname || "User"),
      lastName: capitalizeFirstLetter(user.nickname || "User"),
      bio: "",
    },
    profileDates: {
      createdAt: user.updated_at || "",
      updatedAt: user.updated_at || "",
      loginAt: user.updated_at || "",
    },
    target: 200,
    email: user.email || "",
    mailerliteid: "",
    totals: { ...emptyUserTotals },
    social: { ...emptyUserSocial },
    image: user.picture || "",
  };
  return newUser;
};

import { useAuth0 } from "@auth0/auth0-react";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Heading, Text } from "@chakra-ui/react";
import { PageLayout } from "../components/PageLayout/webui/PageLayout";
import { useAppContext } from "../libs/contextLib";
import { useQuery } from "@tanstack/react-query";
import { buildUser } from "../components/Profile/logic/BuildUser";
import { fetchUser, createUser } from "../writerlogapi/user";
// import { UserProfile } from "../components/Profile/webui/UserProfile";
// import { Loading } from "../components/Loading/webui/Loading";
// import { NoData } from "../components/NoData/webui/NoData";
// import { ChakraProps } from "@chakra-ui/react";

export const DashboardPage = () => {
  const { isAuthenticated } = useAuth0();
  const { currentUser, accessToken } = useAppContext();

  const queryClient = useQueryClient();

  let isEnabled = true;

  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
    enabled: isEnabled,
    retry: 1, // Don't want to waste time if it's 404
  });

  const userCreate = useMutation({
    mutationFn: (data: {}) => createUser(currentUser.sub!, accessToken, data),
    onSuccess: (newProfile) => {
      queryClient.setQueryData(["user"], currentUser.sub, newProfile);
    },
  });

  if (userCreate.isError) {
    return (
      <div>
        <h2>Creating user ID Failed:</h2>
        <p>{JSON.stringify(userCreate.error)}</p>
      </div>
    );
  }

  if (userQuery.error instanceof AxiosError) {
    if (
      userQuery.error.response &&
      userQuery.error.response.status === 404 &&
      !userCreate.isSuccess &&
      !userCreate.isPending
    ) {
      let newUser: UserProfile = buildUser(currentUser);
      userCreate.mutate(newUser);
    }
  }

  if (!isAuthenticated) {
    return (
      <PageLayout>
        <Heading>Dashboard - logged out</Heading>
      </PageLayout>
    );
  }
  return (
    <PageLayout>
      <>
        <Heading>Dashboard</Heading>
        {isAuthenticated ? <Text>Logged in</Text> : <Text>Logged out</Text>}
      </>
    </PageLayout>
  );
};

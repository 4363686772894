import { useState } from "react";
import { Logo } from "./Logo";
import { MenuToggle } from "./MenuToggle";
import { MenuLinks } from "./MenuLinks";
import { NavBarContainer } from "./NavBarContainer";
import { ChakraProps, useColorModeValue } from "@chakra-ui/react";

export const NavBar = (props: ChakraProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const bgColor = useColorModeValue("whiteAlpha.800", "blackAlpha.800");
  const textColor = useColorModeValue("black", "white");

  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer bgColor={bgColor} {...props}>
      <Logo color={textColor} />
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks isOpen={isOpen} textColor={textColor} />
    </NavBarContainer>
  );
};

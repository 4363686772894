import {
  As,
  ChakraProps,
  Editable,
  EditablePreview,
  Flex,
  Input,
  InputGroup,
  Tooltip,
} from "@chakra-ui/react";
import { EditableControls } from "./EditableControls";

type EditableFieldProps = {
  isEditable: boolean;
  onSubmit: (x: string) => Promise<void>;
  onCancel: () => Promise<void>;
  minHeight?: string;
  type: As;
  children: string;
  rest?: ChakraProps;
};

export const EditableField = ({
  isEditable,
  onSubmit,
  onCancel,
  minHeight = "1rem",
  type,
  children,
  ...rest
}: EditableFieldProps) => {
  if (!isEditable) {
    return <>{children}</>;
  }

  return (
    <>
      <Editable
        {...rest}
        width="100%"
        onSubmit={onSubmit}
        onCancel={onCancel}
        defaultValue={children}
        isPreviewFocusable={true}
        selectAllOnFocus={false}
      >
        <Tooltip label="Click to edit">
          <Flex flexWrap={"wrap"}>
            <EditablePreview
              width={"100%"}
              p={1}
              backgroundColor={isEditable ? "gray.100" : "white"}
              _hover={{
                background: "orange.100",
              }}
            />
          </Flex>
        </Tooltip>
        <InputGroup>
          <Input minHeight={minHeight || "initial"} as={type} />
        </InputGroup>
        <EditableControls />
      </Editable>
    </>
  );
};

import {
  FaFacebook as Facebook,
  FaTwitter as Twitter,
  FaGoodreads as Goodreads,
  FaGlobe as Website,
} from "react-icons/fa";

const removePrefix = (prefix: string, url: string) => {
  let account = url.replace(prefix, "");
  return account;
};

export const parseService = (url: string, service: string) => {
  let icon = <></>;
  let serviceColor = "grey";
  let prefix = "";
  let account = "";
  switch (service) {
    case "facebook":
      icon = <Facebook />;
      prefix = "https://facebook.com/";
      serviceColor = "facebook";
      account = removePrefix(prefix, url);
      break;
    case "twitter":
      icon = <Twitter />;
      prefix = "https://twitter.com/";
      serviceColor = "twitter";
      account = removePrefix(prefix, url);
      break;
    case "goodreads":
      icon = <Goodreads />;
      prefix = "https://goodreads.com/author/show/";
      serviceColor = "red";
      account = removePrefix(prefix, url);
      break;
    case "website":
      icon = <Website />;
      serviceColor = "orange";
      prefix = "";
      account = removePrefix(prefix, url);
      break;
    default:
      break;
  }

  return { icon, serviceColor, prefix, account };
};

import { ButtonGroup, IconButton, useEditableControls } from "@chakra-ui/react";
import { SlClose as CloseIcon, SlCheck as CheckIcon } from "react-icons/sl";

export const EditableControls = () => {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    // getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent="end" size="sm" w="full" spacing={2} mt={2}>
      <IconButton
        aria-label="Cancel"
        icon={<CloseIcon />}
        {...getCancelButtonProps()}
      />
      <IconButton
        aria-label="Save"
        bgColor={"green.100"}
        icon={<CheckIcon />}
        {...getSubmitButtonProps()}
      />
    </ButtonGroup>
  ) : null;
};

import {
  Card,
  CardBody,
  ChakraProps,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { buildWordBlocks } from "../logic/buildWords";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "../../../libs/contextLib";
import { fetchUser } from "../../../writerlogapi/user";
import { Loading } from "../../Loading/webui/Loading";
import { NoData } from "../../NoData/webui/NoData";

export const UserWords = (props: ChakraProps) => {
  const { currentUser, accessToken } = useAppContext();
  // Queries
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });
  // Feature enabled?
  if (process.env.REACT_APP_FEATURE_WORDTYPES !== "true") {
    return <></>;
  }

  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  let WordBlocks = buildWordBlocks(userQuery.data);
  if (WordBlocks.length === 0) {
    return <></>;
  }

  return (
    <Card {...props}>
      <CardBody>
        <StatGroup flexWrap="wrap" gap="1em">
          {WordBlocks.map(({ label, number }) => (
            <Stat minWidth="40%" key={label}>
              <StatLabel>{label}</StatLabel>
              <StatNumber>{number} words</StatNumber>
            </Stat>
          ))}
        </StatGroup>
      </CardBody>
    </Card>
  );
};

import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Image,
  Heading,
  ChakraProps,
} from "@chakra-ui/react";
import { buildProjectBlocks } from "../logic/buildProjects";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "../../../libs/contextLib";
import { fetchUser } from "../../../writerlogapi/user";
import { Loading } from "../../Loading/webui/Loading";
import { NoData } from "../../NoData/webui/NoData";

export const UserProjects = (props: ChakraProps) => {
  const { currentUser, accessToken } = useAppContext();
  // Queries
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });

  // Feature enabled?
  if (process.env.REACT_APP_FEATURE_PROJECTS !== "true") {
    return <></>;
  }

  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  let ProjectBlocks = buildProjectBlocks(userQuery.data);

  if (ProjectBlocks.length === 0) {
    return <></>;
  }

  return (
    <Flex
      marginBottom="1em"
      gap="1em"
      justifyContent={{ base: "space-between", md: "flex-start" }}
      flexDirection={{ base: "column", md: "row" }}
    >
      {ProjectBlocks.map(({ name, url, cover, blurb, release }) => (
        <Card
          {...props}
          maxWidth={{ base: "100%", md: "30%" }}
          key={name}
          as="a"
          href={url}
          {...props}
        >
          <CardHeader>
            <Heading fontSize={{ base: "lg", md: "xl", lg: "2xl" }}>
              {name}
            </Heading>
          </CardHeader>
          <CardBody>
            <Image objectFit="cover" src={cover} alt={name} />
          </CardBody>
        </Card>
      ))}
    </Flex>
  );
};

import {
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/stat";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "../../../libs/contextLib";
import { fetchUser } from "../../../writerlogapi/user";
import { Loading } from "../../Loading/webui/Loading";
import { NoData } from "../../NoData/webui/NoData";
import { formatDate } from "../../../libs/dates";
import { Card, CardBody, ChakraProps } from "@chakra-ui/react";

export const UserTotals = (props: ChakraProps) => {
  const { currentUser, accessToken } = useAppContext();
  // Queries
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });
  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  return (
    <Card minWidth="45%" {...props}>
      <CardBody>
        <StatGroup gap="1em">
          <Stat key="words">
            <StatLabel>Total</StatLabel>
            <StatNumber>
              {userQuery.data.totals.words.total.toLocaleString()} words
            </StatNumber>
            <StatHelpText fontSize="xs">
              Since{" "}
              {formatDate(
                userQuery.data.profileDates.firstEntry || "",
                "short"
              )}
            </StatHelpText>
          </Stat>
          <Stat key="days">
            <StatLabel>Total</StatLabel>
            <StatNumber>
              {userQuery.data.totals.days.toLocaleString()} days
            </StatNumber>
            <StatHelpText fontSize="xs">
              Since{" "}
              {formatDate(
                userQuery.data.profileDates.firstEntry || "",
                "short"
              )}
            </StatHelpText>
          </Stat>
        </StatGroup>
      </CardBody>
    </Card>
  );
};

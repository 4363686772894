// Authentication
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@chakra-ui/react";

export const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignup = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/dashboard",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };

  return <Button onClick={() => handleSignup()}>Sign Up</Button>;
};

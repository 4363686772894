import { CardBody, ChakraProps } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "../../../libs/contextLib";
import { fetchUser } from "../../../writerlogapi/user";
import { Loading } from "../../Loading/webui/Loading";
import { NoData } from "../../NoData/webui/NoData";
import { UserBio } from "../../Biography/webui/EditableBio";

type ProfileBodyTypes = { isEditable: boolean; rest?: ChakraProps };
export const ProfileBody = ({ isEditable, ...rest }: ProfileBodyTypes) => {
  const { currentUser, accessToken } = useAppContext();
  // Queries
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });
  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  return (
    <CardBody {...rest}>
      <UserBio
        isEditable={isEditable}
        contents={userQuery.data.name.bio || ""}
      />
    </CardBody>
  );
};

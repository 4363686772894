import {
  Card,
  CardBody,
  Tooltip,
  Text,
  Flex,
  Image,
  ChakraProps,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "../../../libs/contextLib";
import { buildBadgeBlocks } from "../logic/buildBadges";
import { fetchUser } from "../../../writerlogapi/user";
import { Loading } from "../../Loading/webui/Loading";
import { NoData } from "../../NoData/webui/NoData";

export const BadgesCard = (props: ChakraProps) => {
  const { currentUser, accessToken } = useAppContext();
  // Queries
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });

  // Feature enabled?
  if (process.env.REACT_APP_FEATURE_BADGES !== "true") {
    return <></>;
  }

  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  let BadgeBlocks = buildBadgeBlocks(userQuery.data);

  if (!BadgeBlocks.length) {
    return <></>;
  }

  return (
    <Card {...props} marginBottom="1em">
      <CardBody>
        <Flex flexDirection="row" flexWrap="wrap" gap="1em">
          {BadgeBlocks.map(
            ({ id, name, description, image, awarded, awardedDate }) => (
              <Flex key={id} flexDirection={"column"}>
                <Tooltip hasArrow placement="top" label={description}>
                  <Image
                    maxWidth="100px"
                    objectFit="cover"
                    src={image}
                    alt={name}
                    sx={awarded}
                  />
                </Tooltip>
                <Text align="center" fontSize="xs">
                  {awardedDate}
                </Text>
              </Flex>
            )
          )}
        </Flex>
      </CardBody>
    </Card>
  );
};

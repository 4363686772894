import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ComponentType } from "react";
import { Loading } from "../Loading/webui/Loading";

interface AuthenticationGuardProps {
  component: ComponentType;
}

export const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
  component,
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
  });

  return <Component />;
};

import { ChakraProps, Flex } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "../../../libs/contextLib";
import { fetchUser } from "../../../writerlogapi/user";
import { Loading } from "../../Loading/webui/Loading";
import { NoData } from "../../NoData/webui/NoData";
import { TargetField } from "./TargetField";
import { TbTargetArrow as Target } from "react-icons/tb";

type TargetTypes = {
  isEditable: boolean;
  rest?: ChakraProps;
};
export const UserTarget = ({ isEditable, ...rest }: TargetTypes) => {
  const { currentUser, accessToken } = useAppContext();

  // Queries
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });

  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  return (
    <Flex gap="1em">
      <Target size="3rem" />
      <TargetField
        contents={userQuery.data.target || 0}
        isEditable={isEditable}
      />
    </Flex>
  );
};

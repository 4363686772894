// Callback page for login - just the bare header to ease transition
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/PageLayout/webui/PageLayout";
import { Header } from "../components/PageLayout/webui/Header";
import { Container } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/react";

export const CallBackPage = () => {
  const { error } = useAuth0();

  const bgColor = useColorModeValue("whiteAlpha.800", "blackAlpha.800");
  const textColor = useColorModeValue("black", "white");

  if (error) {
    return (
      <PageLayout>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Error
          </h1>
          <div className="content__body">
            <p id="page-description">
              <span>{error.message}</span>
            </p>
          </div>
        </div>
      </PageLayout>
    );
  }

  return (
    <>
      <Header />
      <Container
        paddingY="1em"
        marginY="1em"
        maxWidth={["100%", "80%"]}
        background={bgColor}
        color={textColor}
      ></Container>
    </>
  );
};

import { CardHeader, ChakraProps, Flex } from "@chakra-ui/react";
import { DatesGrid } from "../../Dates/webui/DatesGrid";
import { UserImage } from "../../Image/webui/UserImage";
import { UserNames } from "../../Names/webui/Names";
import { UserTarget } from "../../Target/webui/UserTarget";

type ProfileHeaderTypes = { isEditable: boolean; rest?: ChakraProps };
export const ProfileHeader = ({ isEditable, ...rest }: ProfileHeaderTypes) => {
  return (
    <CardHeader {...rest} display="flex" flexDirection="column" gap="1em">
      <Flex gap="1em">
        <UserImage isEditable={isEditable} />
        <UserNames isEditable={isEditable} />
      </Flex>
      <UserTarget isEditable={isEditable} />
      <DatesGrid />
    </CardHeader>
  );
};

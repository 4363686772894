// Pretty print a date - if it's this year we drop the year number.
// If it's yesterday or today we say so.
// Otherwise date as ordinal and month as word

export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const monthNamesShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const monthNamesInitial = [
  "J",
  "F",
  "M",
  "A",
  "M",
  "J",
  "J",
  "A",
  "S",
  "O",
  "N",
  "D",
];

export const isToday = (date: string): boolean => {
  if (isNaN(Date.parse(date))) {
    // It's wrong, but if something's gone wrong and we got here without a real date,
    // I don't want to try and do anything based on this
    return true;
  }

  const dateObj = new Date(date);
  const req = { year: 0, month: 0, day: 0 };

  req.year = dateObj.getFullYear();
  req.month = dateObj.getMonth();
  req.day = dateObj.getDate();

  const thisYear = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  const thisDay = new Date().getDate();

  if (req.year === thisYear && req.month === thisMonth && req.day === thisDay) {
    return true;
  }
  return false;
};

// Call with 'short' to reduce month to 3 letters
export const formatDate = (date: string, type = "long"): string => {
  if (isNaN(Date.parse(date))) {
    return "";
  }
  const dateObj = new Date(date);
  const req = { year: 0, month: 0, day: 0 };

  req.year = dateObj.getFullYear();
  req.month = dateObj.getMonth();
  req.day = dateObj.getDate();

  const thisYear = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  const thisDay = new Date().getDate();

  const monthName =
    type === "long"
      ? monthNames[req.month]
      : monthNames[req.month].substring(0, 3);

  // Most of the time it'll be this format
  let formattedDate = `${ordinal(req.day)} ${monthName}`;

  // If it's not this year, we need to add the year
  if (thisYear !== req.year) {
    formattedDate = `${ordinal(req.day)} ${monthName} ${req.year}`;
  }

  // if it's this year and month
  if (thisYear === req.year && thisMonth === req.month) {
    // If it's today, let's just say that
    if (thisDay === req.day) {
      formattedDate = "today";
    }
    // Or yesterday
    if (thisDay - 1 === req.day) {
      formattedDate = "yesterday";
    }
  }
  return formattedDate;
};

const ordinal = (number: number): string => {
  // Special cases
  switch (number.toString()) {
    case "11":
    case "12":
    case "13":
      return `${number}th`;
    default:
      break;
  }
  switch (number.toString()[number.toString().length - 1]) {
    case "1":
      return `${number}st`;
    case "2":
      return `${number}nd`;
    case "3":
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};

export const getDaysInMonth = (year: number, month: number): number => {
  // Day 0 of the next month is the same as the last day of this month
  return new Date(year, month + 1, 0).getDate();
};

export const getPrevMonth = (year: number, month: number): number[] => {
  var prevYear = year;
  var prevMonth = month - 1;
  if (prevMonth < 0) {
    prevMonth = 11;
    prevYear = prevYear - 1;
  }
  return [prevYear, prevMonth];
};

export const getNextMonth = (year: number, month: number): number[] => {
  var nextMonth;
  var nextYear;

  // We need to know today, to know this month
  const today = new Date();

  // If we're in the current calendar month
  if (year === today.getFullYear() && month === today.getMonth()) {
    nextYear = 0;
    nextMonth = 99;
  } else {
    nextMonth = month + 1;
    nextYear = year;
    if (nextMonth > 11) {
      nextMonth = 0;
      nextYear = nextYear + 1;
    }
  }
  return [nextYear, nextMonth];
};

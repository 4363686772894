export const roundNumber = (input: number): string => {
    var words = [
        '',
        'thousand',
        'million',
        'billion',
        'trillion',
        'quadrillion',
    ];

    if (typeof input === 'number') {
        if (input < 1000) {
            return `${input}`;
        }
        // Log division is equivalent to working out the exponent
        var exp = Math.floor(Math.log(input) / Math.log(1000));
        // Return the rounded down version of what's raised to that exponent
        return (
            'over ' + Math.floor(input / Math.pow(1000, exp)) + ' ' + words[exp]
        );
        // If you want to round properly, including up:
        // return (input / Math.pow(1000, exp)).toFixed(0) + " " + words[exp];
    } else {
        return `${input} is not a number`;
    }
};

// Return a number as two digits, adding a leading 0 if required
// Always returns a string for consistency
export const twoDigit = (number: number): string => {
    return number < 10 ? '0' + number : number.toString();
};

// Return the number with the ordinal suffix
export const ordinal = (number: number) => {
    // Special cases
    switch (number.toString()) {
        case '11':
        case '12':
        case '13':
            return `${number}th`;
        default:
            break;
    }
    switch (number.toString()[number.toString().length - 1]) {
        case '1':
            return `${number}st`;
        case '2':
            return `${number}nd`;
        case '3':
            return `${number}rd`;
        default:
            return `${number}th`;
    }
};

// Authentication
import {
  Card,
  CardBody,
  CardHeader,
  ChakraProps,
  Heading,
  Skeleton,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
// Components
import { roundNumber } from "../libs/numbers";
import { fetchTotals } from "../writerlogapi/totals";
import { PageLayout } from "../components/PageLayout/webui/PageLayout";

export const FrontPage = (props: ChakraProps) => {
  const totalsQuery = useQuery({
    queryKey: ["totals"],
    queryFn: fetchTotals,
  });

  if (totalsQuery.isLoading) {
    return <Skeleton height="100px" />;
  }
  if (totalsQuery.error instanceof Error) {
    return <span>Error: {totalsQuery.error.message}</span>;
  }

  return (
    <PageLayout>
      <Card {...props}>
        <CardHeader>
          <Heading fontSize="2xl">Welcome to WriterLog!</Heading>
        </CardHeader>
        <CardBody paddingTop="0">
          Why not join our{" "}
          {totalsQuery.data.users.total > 10
            ? totalsQuery.data.users.total.toLocaleString()
            : ""}{" "}
          users. Together we've logged{" "}
          {roundNumber(totalsQuery.data.words.total)} words so far!
        </CardBody>
      </Card>
    </PageLayout>
  );
};

import { useState, useEffect } from "react";

// Query Libraries
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Route, Routes } from "react-router-dom";

import { AppContext } from "./libs/contextLib";
import { useAuth0, User } from "@auth0/auth0-react";

// UI Library
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme/theme";
import "@fontsource/dancing-script";
import "@fontsource/source-serif-pro";
import "@fontsource/source-sans-pro";

import { FrontPage } from "./pages/FrontPage";
import { CallBackPage } from "./pages/Callback";
import { DashboardPage } from "./pages/Dashboard";
import { ProfilePage } from "./pages/ProfilePage";
import { InfoPage } from "./pages/InfoPage";

import { Loading } from "./components/Loading/webui/Loading";

import "./App.css";
import { AuthenticationGuard } from "./components/Utils/authentication-guard";

// Wrap it all in Query, so we have easy API access
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // globally default to 60 seconds
      staleTime: 1000 * 60,
    },
  },
});

export const App: React.FC = () => {
  const [accessToken, setAccessToken] = useState("");
  const [currentUser, setCurrentUser] = useState(new User());
  // Need to know if we're logged in or not
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  useEffect(() => {
    const onLoad = async () => {
      if (isAuthenticated && user) {
        setCurrentUser(user);
        try {
          getAccessTokenSilently().then((token) => setAccessToken(token));
        } catch (err) {
          console.error(err);
        }
      }
    };

    onLoad();
  }, [user, isAuthenticated, getAccessTokenSilently]);

  // Assume we're still loading the page
  const [loading, setLoading] = useState(true);
  // Triggers when page is loaded
  useEffect(() => {
    setLoading(false);
  }, []);

  if (isLoading || loading) {
    return <Loading />;
  }

  return (
    <ChakraProvider theme={theme}>
      <AppContext.Provider
        value={{
          currentUser: currentUser,
          accessToken: accessToken,
          isLoading: true,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/" element={<FrontPage />} />
            <Route path="/callback" element={<CallBackPage />} />
            <Route path="/loading" element={<Loading />} />
            <Route path="/info" element={<InfoPage />} />
            <Route
              path="/dashboard"
              element={<AuthenticationGuard component={DashboardPage} />}
            />
            <Route
              path="/profile"
              element={<AuthenticationGuard component={ProfilePage} />}
            />
          </Routes>
        </QueryClientProvider>
      </AppContext.Provider>
    </ChakraProvider>
  );
};

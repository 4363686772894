import { CardFooter, ChakraProps } from "@chakra-ui/react";
import { SocialButtons } from "../../Social/webui/SocialButtonGroup";
import { EditableSocialButtons } from "../../Social/webui/EditableSocialButtonGroup";

type ProfileFooterTypes = { isEditable: boolean; rest?: ChakraProps };
export const ProfileFooter = ({ isEditable, ...rest }: ProfileFooterTypes) => {
  if (isEditable) {
    return (
      <CardFooter {...rest}>
        <EditableSocialButtons />
      </CardFooter>
    );
  } else {
    return (
      <CardFooter {...rest}>
        <SocialButtons />
      </CardFooter>
    );
  }
};

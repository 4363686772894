import { ChakraProps, Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

type NavBarContainerProps = { children: ReactNode; rest?: ChakraProps };
export const NavBarContainer = ({
  children,
  ...rest
}: NavBarContainerProps) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={"1em"}
      paddingX={"1em"}
      paddingY={"0.5em"}
      {...rest}
    >
      {children}
    </Flex>
  );
};

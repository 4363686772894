import { formatDate } from "../../../libs/dates";

export const buildStreakBlocks = (profile: UserProfile) => {
  let StreakBlocks = [];

  // No streaks yet?
  if (!profile?.currentStreak && !profile?.longestStreak) {
    return [];
  }

  // This actually simplifies the logic:
  let showCurrent = false;
  let showLongest = false;

  // If we have a current streak:
  if (profile.currentStreak?.length) {
    showCurrent = true;
  }
  // If we have a longest streak:
  if (profile.longestStreak?.length) {
    // And we have a current one:
    if (profile.currentStreak?.length) {
      // And the longest streak is longer than our current:
      if (profile.longestStreak?.length > profile.currentStreak?.length) {
        // Add the longest to the list
        showLongest = true;
      }
      // Here we have a current streak more than or equal the longest, so we won't push the longest
    } else {
      // No current streak, so show the longest
      showLongest = true;
    }
  }

  // If we have a current streak (which we almost always will)
  if (showCurrent) {
    // And we also want to show the longest, then we'll mark as such
    if (showLongest) {
      StreakBlocks.push({
        label: "Current streak",
        number: profile.currentStreak?.length,
        from: formatDate(profile.currentStreak?.startDate!, "short"),
        to: formatDate(profile.currentStreak?.endDate!, "short"),
      });
    } else {
      // We're ONLY showing current, because it's the longest also
      StreakBlocks.push({
        label: "Current & longest streak",
        number: profile.currentStreak?.length,
        from: formatDate(profile.currentStreak?.startDate!, "short"),
        to: formatDate(profile.currentStreak?.endDate!, "short"),
      });
    }
  }

  if (showLongest) {
    StreakBlocks.push({
      label: "Longest streak",
      number: profile.longestStreak?.length,
      from: formatDate(profile.longestStreak?.startDate!, "short"),
      to: formatDate(profile.longestStreak?.endDate!, "short"),
    });
  }
  if (!showCurrent && !showLongest) {
    StreakBlocks.push({
      label: "Current streak",
      number: 0,
      from: "Enter some word counts",
      to: "track your progress",
    });
  }

  return StreakBlocks;
};

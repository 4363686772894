import { Card, ChakraProps } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "../../../libs/contextLib";
import { fetchUser } from "../../../writerlogapi/user";
import { Loading } from "../../Loading/webui/Loading";

import { NoData } from "../../NoData/webui/NoData";
import { ProfileHeader } from "./ProfileHeader";
import { ProfileBody } from "./ProfileBody";
import { ProfileFooter } from "./ProfileFooter";

export const UserProfile = (props: ChakraProps) => {
  const { currentUser, accessToken } = useAppContext();
  // Queries
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });
  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  // By default, we can't change anything
  let isEditable = false;
  // But if it's our own record
  if (userQuery.data.userID === currentUser.sub) {
    isEditable = true;
  }

  // @@@FIXME@@@ Editing socials is harder than I thought, so disable for now

  return (
    <Card {...props} flexDirection={{ base: "column", md: "row" }}>
      <ProfileHeader isEditable={isEditable} />
      <ProfileBody isEditable={isEditable} />
      <ProfileFooter isEditable={false} />
    </Card>
  );
  // }
};

import { Box, ChakraProps } from "@chakra-ui/react";
import { SlClose as CloseIcon, SlMenu as MenuIcon } from "react-icons/sl";

type MenuToggleProps = {
  toggle: React.MouseEventHandler<HTMLDivElement>;
  isOpen: Boolean;
  rest?: ChakraProps;
};

export const MenuToggle = ({ toggle, isOpen, ...rest }: MenuToggleProps) => {
  return (
    <Box {...rest} display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

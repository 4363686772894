import { useAuth0 } from "@auth0/auth0-react";
import { Box, ChakraProps, Stack } from "@chakra-ui/react";
import { LoginButton } from "../../Buttons/webui/LoginButton";
import { SignupButton } from "../../Buttons/webui/SignupButton";
import { LogoutButton } from "../../Buttons/webui/LogoutButton";
import { SlInfo as Info } from "react-icons/sl";

import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";

const LinkList = (props: ChakraProps) => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return (
      <>
        <ChakraLink as={ReactRouterLink} {...props} to="/dashboard">
          Dashboard
        </ChakraLink>
        <ChakraLink as={ReactRouterLink} {...props} to="/graphs">
          Graphs
        </ChakraLink>
        <ChakraLink as={ReactRouterLink} {...props} to="/profile">
          Profile
        </ChakraLink>
        <LogoutButton />
        <ChakraLink as={ReactRouterLink} {...props} to="/info">
          <Info />
        </ChakraLink>
      </>
    );
  } else {
    return (
      <>
        <SignupButton />
        <LoginButton />
      </>
    );
  }
};

type MenuLinksProps = { isOpen: Boolean; textColor?: string };
export const MenuLinks = ({ isOpen, textColor }: MenuLinksProps) => {
  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      <Stack
        spacing={8}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        <LinkList textColor={textColor} />
      </Stack>
    </Box>
  );
};

import {
  Card,
  CardBody,
  Text,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  ChakraProps,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "../../../libs/contextLib";
import { fetchUser } from "../../../writerlogapi/user";
import { Loading } from "../../Loading/webui/Loading";
import { NoData } from "../../NoData/webui/NoData";
import { buildStreakBlocks } from "../logic/buildStreaks";

export const UserStreaks = (props: ChakraProps) => {
  const { currentUser, accessToken } = useAppContext();
  // Queries
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });
  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  let StreakBlocks = buildStreakBlocks(userQuery.data);

  if (StreakBlocks.length === 0) {
    return <></>;
  }

  return (
    <Card {...props} minWidth="45%">
      <CardBody>
        <StatGroup gap="1em">
          {StreakBlocks.map(({ label, number, from, to }) => (
            <Stat key={label}>
              <StatLabel>{label}</StatLabel>
              <StatNumber>{number} days</StatNumber>
              <StatHelpText>
                <Text fontSize="xs">{from} to</Text>
                <Text fontSize="xs">{to}</Text>
              </StatHelpText>
            </Stat>
          ))}
        </StatGroup>
      </CardBody>
    </Card>
  );
};

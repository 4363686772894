export const buildWordBlocks = (profile: UserProfile) => {
  let WordBlocks = [];

  for (var type in profile.totals.words) {
    switch (type) {
      case "fiction":
      case "nonfiction":
      case "editing":
      case "other":
        WordBlocks.push({
          label: type,
          number: profile.totals.words[type],
        });
    }
  }

  return WordBlocks;
};

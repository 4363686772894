import { ButtonGroup, ChakraProps } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "../../../libs/contextLib";
import { fetchUser } from "../../../writerlogapi/user";
import { Loading } from "../../Loading/webui/Loading";
import { NoData } from "../../NoData/webui/NoData";
import { AddSocialButton } from "./AddSocialButton";
import { EditableSocialButton } from "./EditableSocialButton";
import { SocialButton } from "./SocialButton";

export const EditableSocialButtons = (props: ChakraProps) => {
  const { currentUser, accessToken } = useAppContext();
  // Queries
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });
  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  // By default, we can't change anything
  let isEditable = false;
  // But if it's our own record
  if (userQuery.data.userID === currentUser.sub) {
    isEditable = true;
  }

  if (!userQuery.data.social) {
    return <></>;
  }

  if (isEditable) {
    return (
      <ButtonGroup
        gap="0.5em"
        spacing="0"
        flexDirection={{ base: "column", md: "row" }}
        flexWrap={"wrap"}
        justifyItems="flex-end"
        {...props}
      >
        {userQuery.data.social.map(({ type, url }) => (
          <EditableSocialButton key={url} service={type} url={url} />
        ))}
        <AddSocialButton />
      </ButtonGroup>
    );
  } else {
    return (
      <ButtonGroup
        gap="0.5em"
        spacing="0"
        flexDirection={{ base: "row", md: "column" }}
        justifyItems="flex-end"
        {...props}
      >
        {userQuery.data.social.map(({ type, url }) => (
          <SocialButton key={url} service={type} url={url} />
        ))}
      </ButtonGroup>
    );
  }
};

import { Box, ChakraProps, Text } from "@chakra-ui/react";

export const Logo = (props: ChakraProps) => {
  return (
    <Box {...props}>
      <Text
        fontSize={"xxx-large"}
        fontFamily={"Dancing Script"}
        fontWeight={"bold"}
      >
        Writer Log
      </Text>
    </Box>
  );
};

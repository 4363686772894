import { ButtonGroup, ChakraProps } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "../../../libs/contextLib";
import { fetchUser } from "../../../writerlogapi/user";
import { Loading } from "../../Loading/webui/Loading";
import { NoData } from "../../NoData/webui/NoData";
import { SocialButton } from "./SocialButton";

export const SocialButtons = (props: ChakraProps) => {
  const { currentUser, accessToken } = useAppContext();
  // Queries
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });
  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  if (!userQuery.data.social) {
    return <></>;
  }

  return (
    <ButtonGroup
      gap="0.5em"
      spacing="0"
      flexDirection={{ base: "row", md: "column" }}
      justifyItems="flex-end"
      {...props}
    >
      {userQuery.data.social.map(({ type, url }) => (
        <SocialButton key={url} service={type} url={url} />
      ))}
    </ButtonGroup>
  );
};

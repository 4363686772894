// import { PageLayout } from "../components/PageLayout/webui/PageLayout";
// import { Skeleton } from "@chakra-ui/react";
import "./Loading.css";

export const Loading = () => {
  const loadingImg = "/images/icons/loader.svg";

  return (
    <div className="loader">
      <img src={loadingImg} alt="Loading..." />
    </div>
  );
};

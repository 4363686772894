import { ChakraProps, EditableTextarea } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { fetchUser, updateUser } from "../../../writerlogapi/user";
import { EditableField } from "../../Utils/webui/EditableField";
import { Loading } from "../../Loading/webui/Loading";
import { NoData } from "../../NoData/webui/NoData";

type EditableBioProps = {
  contents: string;
  isEditable: boolean;
  rest?: ChakraProps;
};
export const UserBio = ({
  contents,
  isEditable,
  ...rest
}: EditableBioProps) => {
  const { currentUser, accessToken } = useAppContext();
  const queryClient = useQueryClient();
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });

  const userUpdate = useMutation({
    mutationFn: (data: {}) => updateUser(currentUser.sub!, accessToken, data),
    onSuccess: (newProfile) =>
      queryClient.setQueryData(["user"], currentUser.sub, newProfile),
  });

  let [fieldContents, setFieldContents] = useState(contents);

  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  async function handleSubmit(contents: string) {
    // Set the contents to the new value (obviously we should error check)
    setFieldContents(contents);
    // And update the back end
    userUpdate.mutate({ name: { bio: contents } });
  }
  async function handleCancel() {
    // Reset to the current value, erases the changes.
    setFieldContents(fieldContents);
  }

  return (
    <EditableField
      onSubmit={(x: string) => handleSubmit(x)}
      onCancel={handleCancel}
      isEditable={isEditable}
      type={EditableTextarea}
      minHeight="8rem"
      {...rest}
    >
      {contents}
    </EditableField>
  );
};

import { useColorModeValue, Container } from "@chakra-ui/react";
// import { Text } from "@chakra-ui/react";
import { Header } from "./Header";

interface Props {
  children: JSX.Element;
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  // const environment = JSON.stringify(process.env);

  const bgColor = useColorModeValue("whiteAlpha.800", "blackAlpha.800");
  const textColor = useColorModeValue("black", "white");

  return (
    <>
      <Header />
      <Container
        paddingY="1em"
        marginY="1em"
        maxWidth={["100%", "80%"]}
        background={bgColor}
        color={textColor}
      >
        {/* <Text>{environment}</Text> */}

        {children}
      </Container>
    </>
  );
};

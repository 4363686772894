import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { formatDate } from "../../../libs/dates";

export const Updates = () => {
  type updates = {
    date: string;
    version: string;
    changes: { short: string; long: string }[];
  };
  const updatesList: updates[] = require("../logic/updates.json");

  return (
    <Flex flexDirection={{ base: "column" }} gap="1em" flexWrap="wrap">
      {updatesList.map(({ date, version, changes }, index) => (
        <div key={index}>
          <Card>
            <CardHeader paddingBottom="0">
              <Heading fontSize="xl">
                {version} ({formatDate(date)})
              </Heading>
            </CardHeader>
            <CardBody as={Stack} divider={<StackDivider />}>
              {changes.map(({ short, long }) => (
                <>
                  <Text as={"b"}>{short}</Text>
                  <Text>{long}</Text>
                </>
              ))}
            </CardBody>
          </Card>
        </div>
      ))}
    </Flex>
  );
};

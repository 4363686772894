import { Avatar, ChakraProps } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "../../../libs/contextLib";
import { fetchUser } from "../../../writerlogapi/user";
import { Loading } from "../../Loading/webui/Loading";
import { NoData } from "../../NoData/webui/NoData";

type UserImageTypes = { isEditable: boolean; rest?: ChakraProps };
export const UserImage = ({ isEditable, ...rest }: UserImageTypes) => {
  const { currentUser, accessToken } = useAppContext();
  // Queries
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });
  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  // @@@FIXME@@@ Editable images
  // // By default, we can't change anything
  // let isEditable = false;
  // // But if it's our own record
  // if (userQuery.data.userID === currentUser.sub) {
  //     isEditable = true;
  // }

  // Default image - ChakraUI has a default of the user's initial instead
  let userImage = "/images/icons/pen.png";
  if (userQuery.data.image) {
    userImage = userQuery.data.image;
  }
  return (
    <Avatar size="lg" name={userQuery.data.name.displayName} src={userImage} />
  );
};

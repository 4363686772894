// 1. import `extendTheme` function
import { extendTheme, type ThemeConfig } from "@chakra-ui/react";

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// 3. extend the theme
export const theme = extendTheme({
  config,
  styles: {
    global: {
      body: {
        minHeight: "100vh",
        backgroundImage: `url('/images/background/orange-top-2048w.jpg')`,
        backgroundPosition: "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
    },
  },
  fonts: {
    // heading: `'Source Sans Pro', sans-serif`,
    body: `'Source Serif Pro', serif`,
  },
});

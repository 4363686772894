import {
  Flex,
  Text,
  Button,
  InputGroup,
  EditableInput,
  ChakraProps,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { capitalizeFirstLetter } from "../../../libs/strings";
import { updateUser } from "../../../writerlogapi/user";
import { EditableField } from "../../Utils/webui/EditableField";
import { FaPlus as Add } from "react-icons/fa";

export const AddSocialButton = ({ ...rest }: ChakraProps) => {
  let service = "Add";
  let icon = <Add />;
  let prefix = "";
  let url = "";
  let colorScheme = "gray";

  let [fieldContents, setFieldContents] = useState(url || "");

  const { currentUser, accessToken } = useAppContext();
  const queryClient = useQueryClient();

  const userUpdate = useMutation({
    mutationFn: (data: {}) => updateUser(currentUser.sub!, accessToken, data),
    onSuccess: (newProfile) =>
      queryClient.setQueryData(["user"], currentUser.sub, newProfile),
  });

  async function handleSubmit(contents: string) {
    // Set the contents to the new value (obviously we should error check)
    setFieldContents(contents);
    // And update the back end
    userUpdate.mutate({ name: { service: contents } });
  }
  async function handleCancel() {
    // Reset to the current value, erases the changes.
    setFieldContents(fieldContents);
  }

  return (
    <Flex
      {...rest}
      flexDirection={"column"}
      gap="1em"
      border={"1px solid black"}
      padding="1em"
      flexBasis={"45%"}
      flexGrow={"1"}
    >
      <Button colorScheme={colorScheme} leftIcon={icon}>
        {capitalizeFirstLetter(service)}
      </Button>
      <Text>{prefix}</Text>
      <InputGroup flexDirection={"column"}>
        <EditableField
          onSubmit={(x: string) => handleSubmit(x)}
          onCancel={handleCancel}
          isEditable={true}
          type={EditableInput}
        >
          {fieldContents}
        </EditableField>
      </InputGroup>
    </Flex>
  );
};

import { Button, ChakraProps } from "@chakra-ui/react";
import { parseService } from "../logic/parseService";

type SocialButtonProps = {
  service: string;
  url: string | undefined;
  rest?: ChakraProps;
};
export const SocialButton = ({ service, url, ...rest }: SocialButtonProps) => {
  if (!url) {
    return <></>;
  }

  const { icon, serviceColor, prefix, account } = parseService(url, service);

  return (
    <Button {...rest} as="a" href={prefix + account} colorScheme={serviceColor}>
      {icon}
    </Button>
  );
};

import { SimpleGrid, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "../../../libs/contextLib";
import { formatDate } from "../../../libs/dates";
import { fetchUser } from "../../../writerlogapi/user";
import { Loading } from "../../Loading/webui/Loading";
import { NoData } from "../../NoData/webui/NoData";

export const DatesGrid = () => {
  const { currentUser, accessToken } = useAppContext();
  // Queries
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });
  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  return (
    <SimpleGrid
      maxWidth={{ base: "50%", md: "100%" }}
      columns={2}
      fontSize="xx-small"
    >
      <Text>Member since:</Text>
      <Text>{formatDate(userQuery.data.profileDates.createdAt, "short")}</Text>
      <Text>Last seen:</Text>
      <Text>{formatDate(userQuery.data.profileDates.loginAt, "short")}</Text>
    </SimpleGrid>
  );
};

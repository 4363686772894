import {
  Button,
  ChakraProps,
  EditableInput,
  Flex,
  Text,
  InputGroup,
} from "@chakra-ui/react";
import { useState } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUser } from "../../../writerlogapi/user";
import { EditableField } from "../../Utils/webui/EditableField";
import { parseService } from "../logic/parseService";
import { capitalizeFirstLetter } from "../../../libs/strings";

type SocialButtonProps = {
  service: string;
  url: string | undefined;
  rest?: ChakraProps;
};
export const EditableSocialButton = ({
  service,
  url,
  ...rest
}: SocialButtonProps) => {
  const { icon, serviceColor, prefix, account } = parseService(
    url || "",
    service
  );

  let [fieldContents, setFieldContents] = useState(account);

  const { currentUser, accessToken } = useAppContext();
  const queryClient = useQueryClient();

  const userUpdate = useMutation({
    mutationFn: (data: {}) => updateUser(currentUser.sub!, accessToken, data),
    onSuccess: (newProfile) =>
      queryClient.setQueryData(["user"], currentUser.sub, newProfile),
  });

  async function handleSubmit(contents: string) {
    // Set the contents to the new value (obviously we should error check)
    setFieldContents(contents);
    // And update the back end
    userUpdate.mutate({ social: [{ type: service, url: contents }] });
  }
  async function handleCancel() {
    // Reset to the current value, erases the changes.
    setFieldContents(fieldContents);
  }

  return (
    <Flex
      {...rest}
      flexDirection={"column"}
      gap="1em"
      border={"1px solid black"}
      padding="1em"
      flexBasis={"45%"}
      flexGrow={"1"}
    >
      <Button colorScheme={serviceColor} leftIcon={icon}>
        {capitalizeFirstLetter(service)}
      </Button>
      <Text>{prefix}</Text>
      <InputGroup flexDirection={"column"}>
        <EditableField
          onSubmit={(x: string) => handleSubmit(x)}
          onCancel={handleCancel}
          isEditable={true}
          type={EditableInput}
          {...rest}
        >
          {fieldContents}
        </EditableField>
      </InputGroup>
    </Flex>
  );
};

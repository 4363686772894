import { formatDate } from "../../../libs/dates";

export const buildProjectBlocks = (profile: UserProfile) => {
  if (!profile.projects) {
    return [];
  }
  let ProjectBlocks = [];

  for (var instance in profile.projects) {
    // There should be a name for it, but just in case
    let name = profile.projects[instance].name || "Untitled";

    // URL might be blank, we'll make a dummy as default
    let website = "#";
    // If they have a URL, we'll use it
    if (profile.projects[instance].website) {
      website = profile.projects[instance].website!;
      // ___FIXME___ Need to scan through all 'types' of social for a website?
      // } else {
      // If not, and they have a personal website, we'll use that
      // if (profile.social && profile.social.website) {
      //     website = profile.social.website;
      // }
    }

    // Might not have a cover yet, so we'll use a placeholder
    let cover =
      profile.projects[instance].cover || "/images/icons/blankcover.png";

    // Blurb might be blank
    let blurb = profile.projects[instance].blurb || "";

    let release = "Coming soon";
    if (profile.projects[instance].releaseDate) {
      release = formatDate(profile.projects[instance].releaseDate!);
    }

    ProjectBlocks.push({
      name: name,
      url: website,
      cover: cover,
      blurb: blurb,
      release: release,
    });
  }
  return ProjectBlocks;
};

import { Alert, AlertIcon, Link, Text } from "@chakra-ui/react";

export const Banner = () => {
  let linkText = " please report any problems to ";
  if (Math.random() < 0.2) {
    linkText = " please email suggestions to ";
  }
  return (
    <Alert status="warning" variant="solid" justifyContent="center">
      <AlertIcon />
      <Text display={{ base: "none", md: "block" }}>
        This site is still under development -
      </Text>
      <Link href="mailto:bugs@writerlog.app">
        <Text>
          {linkText}
          bugs@writerlog.app
        </Text>
      </Link>
    </Alert>
  );
};

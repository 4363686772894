import { Heading, Box, Text, ChakraProps } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "../../../libs/contextLib";
import { fetchUser } from "../../../writerlogapi/user";
import { Loading } from "../../Loading/webui/Loading";
import { NoData } from "../../NoData/webui/NoData";
import { DisplayName } from "./EditableDisplayName";

type UserNameTypes = { isEditable: boolean; rest?: ChakraProps };
export const UserNames = ({ isEditable, ...rest }: UserNameTypes) => {
  const { currentUser, accessToken } = useAppContext();
  // Queries
  const userQuery = useQuery({
    queryKey: ["user", currentUser.sub, accessToken],
    queryFn: fetchUser,
  });
  if (userQuery.isLoading) {
    return <Loading />;
  }
  if (userQuery.error instanceof Error) {
    return <span>Error: {userQuery.error.message}</span>;
  }
  if (!userQuery.data) {
    return <NoData />;
  }

  return (
    <Box>
      <Heading fontSize="2xl">
        <DisplayName
          isEditable={isEditable}
          contents={userQuery.data.name.displayName || ""}
        />
      </Heading>
      <Text>
        {userQuery.data.name.firstName || ""}{" "}
        {userQuery.data.name.lastName || ""}
      </Text>
      <Text fontSize="xs">{userQuery.data.email || ""} </Text>
    </Box>
  );
};

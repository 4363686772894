import { PageLayout } from "../components/PageLayout/webui/PageLayout";
import { Updates } from "../components/Utils/webui/Updates";

export const InfoPage = () => {
  return (
    <PageLayout>
      <Updates />
    </PageLayout>
  );
};

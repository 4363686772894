// FIXME Not actually implemented

import { SystemStyleObject } from "@chakra-ui/react";
import { formatDate } from "../../../libs/dates";

export const buildBadgeBlocks = (profile: UserProfile) => {
  if (!profile.badges) {
    return [];
  }
  let BadgeBlocks = [];

  for (var instance in profile.badges) {
    // All data should be there for it, but just in case
    let id = profile.badges[instance].badgeID || "untitled";
    // let name = profile.badges[instance].name || 'Untitled';
    // let description =
    //     profile.badges[instance].description || 'No description available';
    // let image = profile.badges[instance].image || '/images/icons/pen.png';
    let awarded: SystemStyleObject = profile.badges[instance].awardedDate
      ? {}
      : { opacity: "20%" };
    let awardedDate =
      formatDate(profile.badges[instance].awardedDate, "short") || "";

    // Yes I could have done it all in one go, but this is clearer -
    // and works better in other scenarios where there's logic to the assignments
    BadgeBlocks.push({
      id: id,
      name: "Not yet",
      description: "Not yet",
      image: "https://picsum.photos/300/300",
      awarded: awarded,
      awardedDate: awardedDate,
    });
  }

  return BadgeBlocks;
};
